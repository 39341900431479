<template>
  <v-container
    v-bind="propsPageMainContainer"
    data-cy="documents-shares-edit-page"
  >
    <v-row v-bind="propsPageMainRow">
      <v-col v-bind="propsPageMainCol">
        <v-card v-bind="propsPageMainCard">
          <div v-bind="propsPageTitleBar">
            <div v-bind="propsPageTitle">
              {{ $t("documentsShares.editTitle") }}
            </div>
            <div class="flex-grow-1"></div>
            <v-btn
              v-bind="propsButtonIconAction"
              small
              :to="{ name: 'documents-index' }"
              data-cy="button-index"
            >
              <v-icon>$pageIndex</v-icon>
            </v-btn>
          </div>
          <DocumentsSharesEdit
            :documentId="documentId"
            :objectId="objectId"
          ></DocumentsSharesEdit>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DocumentsSharesEdit from "../components/DocumentsSharesEdit.vue";

export default {
  name: "DocumentsSharesEditPage",
  components: {
    DocumentsSharesEdit,
  },
  computed: {
    documentId() {
      let documentId = "";
      if (this.$route.params.documentId) {
        documentId = this.$route.params.documentId.toString();
      }
      return documentId;
    },
    objectId() {
      let objectId = "";
      if (this.$route.params.id) {
        objectId = this.$route.params.id.toString();
      }
      return objectId;
    },
  },
};
</script>
