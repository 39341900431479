var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',_vm._b({attrs:{"data-cy":"documents-shares-edit"}},'v-sheet',_vm.propsCompSheet,false),[(_vm.objectId)?[_c('div',{staticClass:"mb-4"},[_c('v-form',{attrs:{"disabled":_vm.formDisabled},on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"formField"},[_c('div',{staticClass:"formField"},[_c('v-text-field',_vm._b({ref:"urlInfo",attrs:{"label":_vm.$t('documentsShares.shareUrl'),"readonly":"","type":"text"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.clickCopyUrl}},[_c('v-icon',[_vm._v("$copy")])],1)]},proxy:true}],null,false,2651159242),model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}},'v-text-field',_vm.propsFormFields,false))],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuValidFromDate",attrs:{"close-on-content-click":false,"return-value":_vm.editValidFromDate,"transition":"scale-transition","offset-y":"","min-width":"290px","data-cy":"menu-data-inici"},on:{"update:returnValue":function($event){_vm.editValidFromDate=$event},"update:return-value":function($event){_vm.editValidFromDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('documentsShares.validFromDate'),"error-messages":_vm.editValidFromDateErrors,"readonly":"","clearable":"","append-icon":"$datePicker","data-cy":"input-data-inici"},on:{"input":function($event){return _vm.$v.editValidFromDate.$touch()},"blur":function($event){_vm.$v.editValidFromDate.$touch();
                      _vm.$v.editValidFromTime.$touch();},"click:append":function($event){_vm.menuValidFromDate = !_vm.menuValidFromDate}},model:{value:(_vm.editValidFromDate),callback:function ($$v) {_vm.editValidFromDate=$$v},expression:"editValidFromDate"}},'v-text-field',_vm.propsFormFields,false),on))]}}],null,false,2656188135),model:{value:(_vm.menuValidFromDate),callback:function ($$v) {_vm.menuValidFromDate=$$v},expression:"menuValidFromDate"}},[_c('v-date-picker',_vm._b({ref:"pickerValidFromDate",attrs:{"first-day-of-week":"1","locale":_vm.$i18n.locale},model:{value:(_vm.editValidFromDate),callback:function ($$v) {_vm.editValidFromDate=$$v},expression:"editValidFromDate"}},'v-date-picker',_vm.propsFormDatePicker,false),[_c('v-btn',_vm._b({on:{"click":function($event){_vm.menuValidFromDate = false}}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c('v-spacer'),_c('v-btn',_vm._b({on:{"click":function($event){return _vm.$refs.menuValidFromDate.save(_vm.editValidFromDate)}}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.save")))])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuValidFromTime",attrs:{"close-on-content-click":false,"transition":"scale-transition","return-value":_vm.editValidFromTime,"min-width":"290px"},on:{"update:returnValue":function($event){_vm.editValidFromTime=$event},"update:return-value":function($event){_vm.editValidFromTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('documentsShares.validFromTime'),"error-messages":_vm.editValidFromTimeErrors,"readonly":"","clearable":"","append-icon":"$timePicker"},on:{"input":function($event){return _vm.$v.editValidFromTime.$touch()},"blur":function($event){_vm.$v.editValidFromDate.$touch();
                      _vm.$v.editValidFromTime.$touch();},"click:append":function($event){_vm.menuValidFromTime = !_vm.menuValidFromTime}},model:{value:(_vm.editValidFromTime),callback:function ($$v) {_vm.editValidFromTime=$$v},expression:"editValidFromTime"}},'v-text-field',_vm.propsFormFields,false),on))]}}],null,false,1066762933),model:{value:(_vm.menuValidFromTime),callback:function ($$v) {_vm.menuValidFromTime=$$v},expression:"menuValidFromTime"}},[_c('v-time-picker',_vm._b({attrs:{"locale":_vm.$i18n.locale},model:{value:(_vm.editValidFromTime),callback:function ($$v) {_vm.editValidFromTime=$$v},expression:"editValidFromTime"}},'v-time-picker',_vm.propsTimePicker,false),[_c('v-btn',_vm._b({on:{"click":function($event){_vm.menuValidFromTime = false}}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c('v-spacer'),_c('v-btn',_vm._b({on:{"click":function($event){return _vm.$refs.menuValidFromTime.save(_vm.editValidFromTime)}}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.save")))])],1)],1)],1)],1)],1),_c('div',{staticClass:"formField"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuValidToDate",attrs:{"close-on-content-click":false,"return-value":_vm.editValidToDate,"transition":"scale-transition","offset-y":"","min-width":"290px","data-cy":"menu-data-inici"},on:{"update:returnValue":function($event){_vm.editValidToDate=$event},"update:return-value":function($event){_vm.editValidToDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('documentsShares.validToDate'),"error-messages":_vm.editValidToDateErrors,"readonly":"","clearable":"","append-icon":"$datePicker","data-cy":"input-data-inici"},on:{"input":function($event){return _vm.$v.editValidToDate.$touch()},"blur":function($event){_vm.$v.editValidToDate.$touch();
                      _vm.$v.editValidToTime.$touch();},"click:append":function($event){_vm.menuValidToDate = !_vm.menuValidToDate}},model:{value:(_vm.editValidToDate),callback:function ($$v) {_vm.editValidToDate=$$v},expression:"editValidToDate"}},'v-text-field',_vm.propsFormFields,false),on))]}}],null,false,3518443943),model:{value:(_vm.menuValidToDate),callback:function ($$v) {_vm.menuValidToDate=$$v},expression:"menuValidToDate"}},[_c('v-date-picker',_vm._b({ref:"pickerValidToDate",attrs:{"first-day-of-week":"1","locale":_vm.$i18n.locale},model:{value:(_vm.editValidToDate),callback:function ($$v) {_vm.editValidToDate=$$v},expression:"editValidToDate"}},'v-date-picker',_vm.propsFormDatePicker,false),[_c('v-btn',_vm._b({on:{"click":function($event){_vm.menuValidToDate = false}}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c('v-spacer'),_c('v-btn',_vm._b({on:{"click":function($event){return _vm.$refs.menuValidToDate.save(_vm.editValidToDate)}}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.save")))])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menuValidToTime",attrs:{"close-on-content-click":false,"transition":"scale-transition","return-value":_vm.editValidToTime,"min-width":"290px"},on:{"update:returnValue":function($event){_vm.editValidToTime=$event},"update:return-value":function($event){_vm.editValidToTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('documentsShares.validToTime'),"error-messages":_vm.editValidToTimeErrors,"readonly":"","clearable":"","append-icon":"$timePicker"},on:{"input":function($event){return _vm.$v.editValidToTime.$touch()},"blur":function($event){_vm.$v.editValidToDate.$touch();
                      _vm.$v.editValidToTime.$touch();},"click:append":function($event){_vm.menuValidToTime = !_vm.menuValidToTime}},model:{value:(_vm.editValidToTime),callback:function ($$v) {_vm.editValidToTime=$$v},expression:"editValidToTime"}},'v-text-field',_vm.propsFormFields,false),on))]}}],null,false,1869202357),model:{value:(_vm.menuValidToTime),callback:function ($$v) {_vm.menuValidToTime=$$v},expression:"menuValidToTime"}},[_c('v-time-picker',_vm._b({attrs:{"locale":_vm.$i18n.locale},model:{value:(_vm.editValidToTime),callback:function ($$v) {_vm.editValidToTime=$$v},expression:"editValidToTime"}},'v-time-picker',_vm.propsTimePicker,false),[_c('v-btn',_vm._b({on:{"click":function($event){_vm.menuValidToTime = false}}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.cancel")))]),_c('v-spacer'),_c('v-btn',_vm._b({on:{"click":function($event){return _vm.$refs.menuValidToTime.save(_vm.editValidToTime)}}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.save")))])],1)],1)],1)],1)],1),_c('div',{staticClass:"formField"},[_c('v-checkbox',_vm._b({attrs:{"label":_vm.$t('documentsShares.isActive'),"error-messages":_vm.editActiveErrors},on:{"input":function($event){return _vm.$v.editActive.$touch()},"blur":function($event){return _vm.$v.editActive.$touch()}},model:{value:(_vm.editActive),callback:function ($$v) {_vm.editActive=$$v},expression:"editActive"}},'v-checkbox',_vm.propsFormFields,false))],1)])],1),_c('div',{staticClass:"d-flex"},[(
          _vm.authCanByUserId(
            'deleteOwn.documentsShares',
            _vm.documentShare.ownerId
          ) || _vm.authCan('deleteOthers.documentsShares')
        )?_c('v-btn',_vm._b({attrs:{"loading":_vm.status.loading,"disabled":!_vm.formReadyForSubmit,"to":{ name: 'documents-shares-delete', params: { id: _vm.objectId } },"data-cy":"button-delete"}},'v-btn',_vm.propsButtonTrash,false),[_vm._v(_vm._s(_vm.$t("common.delete")))]):_vm._e(),_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',_vm._b({attrs:{"loading":_vm.status.loading,"disabled":!_vm.formReadyForSubmit,"data-cy":"button-save"},on:{"click":_vm.clickUpdate}},'v-btn',_vm.propsButtonAction,false),[_vm._v(_vm._s(_vm.$t("common.save")))])],1)]:_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t("documents.error"))+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }